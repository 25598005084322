import { htmlE } from "/WebInterface/new-ui/assets/js/utils/te.js";

const resizableTemplate = htmlE`
  <div class="resizable-wrapper is-absolute w-full h-full">
    ${({ left }) => {
      if (left) {
        return `<div class="resizable-corners resizable-left h-full"></div>`;
      }
    }}

    ${({ right }) => {
      if (right) {
        return `<div class="resizable-corners resizable-right h-full"></div>`;
      }
    }}

    ${({ top }) => {
      if (top) {
        return `<div class="resizable-corners resizable-top w-full"></div>`;
      }
    }}

    ${({ bottom }) => {
      if (bottom) {
        return `<div class="resizable-corners resizable-bottom w-full"></div>`;
      }
    }}
  </div>
`;

export default resizableTemplate;
